.children-section {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}

form.children-doc-form {
    .req-update-textarea {
        display: flex;
        flex-direction: column;
        padding: 30px 15px;
        border-bottom: 1px solid #e8e8e8;
    }
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
        background: #fff;
    }
    td {
        padding: 10px 10px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

}

.tab-detail-top.children-tabs {
    flex-direction: column;
}

.children-tab {
    color: #1C4C7A;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
    span {
        padding: 0 10px;
        font-size: 17px;
        cursor: pointer;
    }
    span.active {
        font-weight: 700;
    }
}




.add-childrendata {
    .form-row {
        display: flex;
        justify-content: space-between;
    }
    
    .req-update-action.j-left {
        justify-content: end;
    }
}

// Responsive CSS
@media screen and (max-width: 767px) { 
    .children-section {
        font-size: 18px;
    }
    .children-section img {
        margin-right: 10px;
    }
    form.children-doc-form table td {
        font-size: 12px;
    }
}
