.print-form ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 30px;
    li {
        width: calc(33.3% - 20px);
        display: flex;
        border-bottom: 1px solid #007dc3;
        padding-bottom: 15px;
        .form-print-icon {
            margin-right: 15px;
        }
        .form-print-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
            h3 {
                font-size: 14px;
                text-transform: uppercase;
                COLOR: #1C4C7A;
                line-height: 18px;
                margin-bottom: 15px;
            }
            p {
                font-size: 12px;
                line-height: 18px;
            }
            .print-action {
                margin: 30px 0 0 0;
                a {
                    font-size: 12px;
                    line-height: 15px;
                    color: #1C4C7A;
                }
            }
        }
    }
}
.form-index {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}



.download-pdf-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    a {
        font-size: 12px;
        line-height: 15px;
        color: #1C4C7A;
    }
}



.download-forms ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 24px;
    li {
        width: calc(25% - 18px);
        display: flex;
        padding-bottom: 30px;
        .form-print-icon {
            margin-right: 15px;
        }
        .form-print-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
            h3 {
                font-size: 14px;
                text-transform: uppercase;
                COLOR: #1C4C7A;
                line-height: 18px;
                margin-bottom: 15px;
            }
            p {
                font-size: 12px;
                line-height: 18px;
            }
            .print-action {
                margin: 15px 0 0 0;
                a {
                    font-size: 12px;
                    line-height: 15px;
                    color: #1C4C7A;
                }
            }
        }
    }
}


.tab-detail-top.d-form {
    flex-direction: column;
}

.tab-detail-top.d-form a {
    width: 100%;
    font-size: 12px;
    background: none;
    color: #007dc3;
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    margin: 0;
}


.tab-detail.forms .tab-detail-bottom {
    border-bottom: 0;
}

// Responsive CSS
@media screen and (max-width: 1024px) { 
    .print-form ul li {
        width: calc(100% - 0px);
    }
    .download-forms ul li {
        width: calc(50% - 18px);
    }
}

@media screen and (max-width: 767px) { 
    .tab-detail.forms {
        padding: 0 0 0 25px!important;
    }
    .form-index img {
        max-width: 36px;
    }
    .form-index {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) { 
    .download-pdf-action {
        justify-content: flex-start;
        margin-top: 5px;
    }
    .download-forms ul li .form-print-content {
        align-content: flex-start;
        width: 100%;
    }
    .download-forms ul li {
        width: calc(100% - 0px);
    }
    .tab-detail-top.d-form {
        margin-top: 30px;
    }
}