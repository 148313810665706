.mc-tabs {
  width: 100%;
  form {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    .manage-corousel-tab {
      display: flex;
      gap: 10px;
      color: #3075b6;
      span {
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
      }
    }
    select {
      margin: 0 0 0 15px;
      max-width: 255px;
      padding: 7px 10px;
    }
  }
}

.normal {
  padding: 7px 20px;
  margin-left: 0;
}

.checkbox-design {
  display: flex;
  align-content: center;
  gap: 10px;
  span {
    display: flex;
    align-content: center;
    label {
      padding: 0;
      margin: 0 0 0 5px;
    }
  }
}

td.table-action a {
  margin: 0 5px;
  display: block;
  text-align: center;
}

td.table-action {
  width: 100px;
  a {
    margin: 0 5px;
  }
}
