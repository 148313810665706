.children-section {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}

form.children-doc-form {
    .req-update-textarea {
        display: flex;
        flex-direction: column;
        padding: 30px 15px;
        border-bottom: 1px solid #e8e8e8;
    }
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 10px 10px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

}

.tab-detail-top.children-tabs {
    flex-direction: column;
}

.children-tab {
    color: #1C4C7A;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
    span {
        padding: 0 10px;
    }
    span.active {
        font-weight: 700;
    }
}


th.date-th {
    width: 120px;
}

th.time-th {
    width: 120px;
}