.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    position: relative;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        width: 200px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 15px;
}

h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.m-profile {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

.pagesubtitle p {
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    line-height: 21px;
    margin-top: 20px;
    color: #EB5757;
}

.table-act img {
    width: 24px;
}

// Responsive CSS
@media screen and (max-width: 991px) { 
    // .one-fourth {
    //     width: calc(50% - 12px);
    // }
    form.mileage .form-row.dandt {
        flex-wrap: wrap;
    }

}
    @media screen and (max-width: 767px) {
    .tab-detail-top a {
        width: 170px;
        padding: 10px 10px;
        font-size: 12px;
    }
    .pagewithsidebar-inner .tab-detail {
        width: calc(100% - 0px);
        padding: 0 0 0 30px;
    }
    .tab-detail-top .m-profile {
        font-size: 18px;
    }
    .tab-detail-top .m-profile img {
        max-width: 36px;
    }
    .tab-detail-bottom {
        padding-bottom: 0;
    }
    .pagewithsidebar-inner {
        padding-bottom: 0;
    }
    // .one-fourth {
    //     width: calc(100% - 12px);
    // }
    .tab-detail.Critical {
        padding: 0 0 0 25px!important;
    }
    .Critical-unusual {
        font-size: 18px;
    }
    .Critical-unusual img {
        max-width: 36px;
    }
    .g-background .form-row .one-half {
        width: calc(100% - 0px);
    }
    .g-background {
        padding: 30px 20px;
    }
    .incident-type ul li {
        width: calc(100% - 0px);
    }
    a.red-btn {
        margin-left: 0;
    }
    .form-row.seli {
        flex-wrap: wrap;
    }
    
    .form-row.seli select {
        margin-bottom: 13px;
    }
    
    .form-row.seli a.blue-btn {
        margin-right: 10px;
    }
    .brenda-detail ul {
        padding: 0 0 0px 0;
    }
}
