.children-section {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}

form.children-attach-form {
    .req-update-textarea {
        display: flex;
        flex-direction: column;
        padding: 30px 15px;
        border-bottom: 1px solid #e8e8e8;
    }
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

}

.tab-detail-top.children-tabs {
    flex-direction: column;
}

.children-tab {
    color: #1C4C7A;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
    span {
        padding: 0 10px;
    }
    span.active {
        font-weight: 700;
    }
}


.download-icon {
    width: 100px;
    text-align: center!important;
}

.search-icon {
    width: 80px;
    text-align: center!important;
}
.doc-attach {
    width: 140px;
    text-align: center;
}

.form-grey {
    background: #f2f2f2;
    margin-top: 20px;
    padding: 40px 30px;
    margin-bottom: 20px;
    .form-row {
        display: flex;
        width: 100%;
    //    gap: 20px;
        .ot-field {
            width: 50%;
        }
    }
}


.form-grey .req-update-action {
    justify-content: flex-start;
    margin-top: 20px;
}
.form-grey .form-row .ot-field select {
    height: 36px;
    width: 100%;
    margin-bottom: 14px;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 10px;
    background: #ffffffcf;
}

.ot-field.half-input {
    width: 50%;
}

// Responsive CSS
@media screen and (max-width: 767px) { 
    .form-grey .form-row .ot-field {
        width: 100%;
    }
    .form-grey {
        background: #f2f2f2;
        margin-top: 0px;
        padding: 30px 15px;
    }
    .form-grey .form-row {
        row-gap: 0px;
        flex-wrap: wrap;
    }
}


.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .close-button {
    border: none;
    display: inline-block;
    padding: 4px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-size: 1.25em;
  }
  
  .topright {
    position: absolute;
    right: 0;
    top: 0
  }

  .btn-bg{
    background-color:#000;color:#fff;
  }


 .iframeWidht{
    width: 950px;
    height: 600px;
 }

 @media only screen and (max-width: 600px) {
    .iframeWidht{
        width: 350px;
        height: 600px;
     }
 }