.small-container {
    max-width: 780px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
}

.fosterfamilyinquiry {
    padding: 100px 0;
h2 {
    font-size: 48px;
    line-height: 1.5;
    text-align: center;
}
p {
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}
}



.foster-form .ot-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    label {
        width: 150px;
        font-size: 16px;
        color: #000;
    }
}

.foster-form .ot-field input, .foster-form .ot-field select {
    height: 44px;
    width: calc(100% - 150px);
    margin-bottom: 0;
    border: 1px solid #dedede;
    border-radius: 0;
}


.act-btn {
    display: flex;
    gap: 50px;
    justify-content: center;
    margin: 40px 0 0 0;
    a {
        width: 180px;
        height: 44px;
        font-size: 16px;
        font-weight: 700;
    }
}

a.bg-outline {
    background: transparent;
    color: #007DC3;
    border: 1px solid #e8e8e8;
}

a.bg-green {
    background: #68AC116E;
}

.foster-form {
    margin: 50px 0 0 0;
}