.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.one-half {
    width: calc(50% - 12px);
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        width: 200px;
        margin-left: 20px;
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 50px;
}

h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.m-profile {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

.pagesubtitle p {
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    line-height: 21px;
    margin-top: 20px;
    color: #EB5757;
}

.table-act img {
    width: 24px;
}




.submit {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    width: 120px;
    border: none;
    font-size: 14px;
}


.req-update-textarea {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    border-bottom: 1px solid #e8e8e8;
}

.req-update-textarea textarea {
    min-height: 150px;
    margin: 20px 0;
}

.req-update-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.req-update-textarea label {
    color: #1C4C7A;
    font-size: 12px;
}

// * Styles for alignment and responsiveness */
.close1{
    background: #c93a3a;
    color: #fff;
    padding: 9px 20px !important;
    border-radius: 5px;
    text-transform: capitalize;
    width: auto;
    border: none;
    margin-left: 0;
    font-size: 14px;
}
.close1 {
    margin-left: 2.33%;
}


/* Media queries for mobile responsiveness */
// @media (max-width: 1080px) {
// .one-third {
// width: 100%;
// padding-right: 0;
// margin-bottom: 20px;
// }
// }