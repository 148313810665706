.form-row.mt-0 {
    margin-top: 30px;
}

.one-full {
    width: 100%;
}

label.checkbox-label {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}


.check-action {
    display: flex;
    align-items: center;
    gap: 15px;
}

span.check-option {
    display: flex;
    margin: 5px 0;
    gap: 10px;
}

.checkbox-grid {
    display: flex;
    grid-gap: 15px;
    grid-gap: 0px;
    gap: 0px;
    flex-wrap: wrap;
    margin: 15px 0 30px;
    
}

.form-row.check-section label {
    padding: 0;
    font-size: 14px;
}

.form-row.check-section h6 {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.g-background h6 {
    font-weight: 700;
}
.g-background {
   .form-row{
    width: 100%;
   }
}
.form-row{
    display: flex;
    flex-wrap: wrap;
    
}

.padtop{
    padding-top: 5px;
}


.tab-detail.Critical.formwithout-login {
    margin: 102px;
}
.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 0px !important;
    position: relative;
    .pagealignpad {
      width: 100% ; 
      padding: 30px;
    }
    
}