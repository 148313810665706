.children-section {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}

.children-tab {
    color: #1C4C7A;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
    span {
        padding: 0 10px;
    }
    span.active {
        font-weight: 700;
    }
}


form.child-case-form {
    display: flex;
    width: 100%;
    gap: 20px;
    h3 {
        color: #1C4C7A;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .form-col {
        width: 43.3%;
       // margin-left: 43.3%;
        .ot-field {
            margin-bottom: 10px;
        }
    }
}
