.top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    padding: 0 15px;
}

a.phone-action {
    color: #fff;
}

.top-bar ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 15px;
}

.top-bar ul a {
    padding: 5px;
}

a.donate-action {
    background: #eab500;
    color: #fff;
    border-radius: 4px;
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 15px;
}
a.logoauth-m {
    display: none;
}
a.logoauth-m img {
    width: 140px;
}
a.logoauth-m img {
    width: 140px;
    margin-left: -17px;
}

@media only screen and (max-width: 600px) {
.top-bar {
    padding: 0;
}
a.logoauth-m {
    display: block;
}
a.logoauth {
    display: none;
}
a.phone-action img {
    width: 16px;
    height: 16px;
}

}