.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        width: 200px;
        margin-left: 20px;
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 15px;
}

h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.m-profile {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

.pagesubtitle p {
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    line-height: 21px;
    margin-top: 20px;
    color: #EB5757;
}

.table-act img {
    width: 24px;
}

// detail



.Assessment-detail-field {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;    
    label {
        font-size: 12px;
        margin-bottom: 5px;
        width: 100%;
        display: block;
        color: #1C4C7A;
        padding: 0 10px;
    }    
}

.Assessment-detail-field .one-four-field {
    width: 25%;
}

.Assessment-detail {
    padding: 50px 0;
}

form.attach-doc-form .form-row {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
}

.form-row-Immidiate label {
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
    display: block;
    color: #1C4C7A;
    padding: 0 10px;
    line-height: 15px;
}

.grey-field {
    background: #f2f2f2;
    padding: 25px 25px 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}


.inner-form-field {
    display: flex;
    width: 100%;
    gap: 15px;
    padding: 15px 0;
    .one-third-field {
        width: 33.3%;
      }
  }


.form-row-Immidiate label {
    display: flex;
    flex-direction: column;
    span {
        color: #000;
        margin-top: 10px;
      }
  }


.label-action {
    display: flex;
    align-items: center;
  }
  .label-check {
    gap: 10px;
    font-size: 12px;
    margin-bottom: 5px;
    width: 160px;
    display: flex;
    color: #1C4C7A;
    padding: 0 10px;
    line-height: 15px;
  }