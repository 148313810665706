.sidebar {
    width: 220px;
    padding-right: 10px;
    transition: all ease-in 300ms;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        a {
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
                margin-right: 10px;
            }
            span {
                border-bottom: 1px solid #E0E0E0;
                line-height: 30px;
                display: flex;
                width: calc(100% - 30px);
                color: #000;
            }
        }
        
    }
    
}
.sidebar ul a span:hover {
    color: #1c4c7a;
}

// Responsive Css

@media screen and (max-width: 767px) {
    .sidebar {
        width: 30px;
        padding-right: 10px;
        transition: all ease-in 300ms;
        // z-index: 1;
        overflow: hidden;
        position: relative;
        min-width: 30px;
    }
    .sidebar ul a span {
        white-space: nowrap;
    }
  }