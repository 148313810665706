
// .input_in_td{
//     outline: none;
// }

// input[type="radio"] {
//     min-width: 16px;
//     height: 16px;
//     border: 1px solid #000;
//     border-radius: 10px;
// }

// .container1{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     //align-items: center;
//     // position: relative;
//     // overflow: auto;
//     height: var(--viewport-height); /* Use the CSS variable */    
// }

// .section-1{
//     flex: 1 1 33.33%;
//     min-height: 100px;
// }


// .pdf-form{
//     width: 85%;
// }

// .section-2{
//     flex: 1 1 33.33%;;
//     min-height: 100px;
// }
// .section-3{
//     flex: 1 1 33.33%;
//     min-height: 100px;
// }

// @media (min-width:398px) {
//     .section-1{
//         flex:1 1 33.33%;
//         flex-direction: column;
//         font-size: small;
       
//     }
    
//     // .section-2{
//     //     flex:1 1 33.33%;
//     //     flex-direction: column;
//     //     font-size: small;
//     // }
//     // .section-3{
//     //     flex:1 1 33.33%;
//     //     flex-direction: column;
    
//     //     font-size: small;
//     // }


    
// @media (max-width: 576px) {

//     .section-1{
//         flex:1 1 33.33%;
//         flex-direction: column;
//         font-size: small;
       
//     }
  
//     .tab-container a {
  
//       flex-basis: 50%; /* Make each tab take 50% of the container width */
  
//     }
  
//   }
//     @media (max-width: 768px) {
//         .main-content {
//         flex-direction: column;
//         }
        
//         .formwithout-login {
//         display: none;
//         }
        
//         .tab-detail {
//         margin-top: 20px;
//         }
        
//         .hard-form {
//         padding: 10px;
//         }
        
//         .heading-foster-parent {
//         font-size: 24px;
//         }
        
//         .form-header img {
//         width: 100%;
//         max-width: 200px;
//         height: auto;
//         }
        
//         .gernal-info-field {
//         flex-direction: column;
//         }
        
//         .gernal-info-field span {
//         margin-bottom: 10px;
//         }
        
//         .yn-checkbox {
//         flex-wrap: wrap;
//         }
        
//         .yn-checkbox span {
//         width: 50%;
//         }
        
//         .yn-field {
//         margin-top: 20px;
//         }
        
//         .parent-name,
//         .gender {
//         flex-direction: column;
//         }
        
//         .parent-name span,
//         .gender span {
//         margin-bottom: 10px;
//         }
        
//         .parent-name input,
//         .gender input {
//         width: 100%;
//         max-width: 200px;
//         }
//         }
    
//         @media print {
        
//          .pagebreak { page-break-after: always; }
        
//         }
        
        
        
    
// }




// // .app-container {

// //   display: flex;

// //   flex-direction: column;

// //   align-items: center;

// //   margin-top: 20px;

// //   padding: 0 10px; /* Add padding to the sides */

// // }


// // .tab-container {

// //   display: flex;

// //   justify-content: space-around;

// //   width: 100%;

// //   margin-bottom: 20px;

// // }


// /* Adjust the styles for smaller screens */



// .form-section {

//   width: 100%; /* Make the form section fill the container width */

// }


// .form-section form {

//   max-width: 400px; /* Limit the form width on larger screens */

//   margin: 0 auto; /* Center the form horizontally */

// }


// .form-section input,

// .form-section button {

//   width: 100%;

//   padding: 10px;

//   margin-bottom: 10px;

// }


// /* Adjust the styles for smaller screens */

// @media (max-width: 576px) {

//   .form-section input,

//   .form-section button {

//     width: 100%; /* Make form fields and buttons fill the container width */

//   }

// }

// new form styling
// .form-section {
//   background-color: yellow;
//   border: 2px solid blue;
//   padding: 10px;
//   margin-bottom: 20px;
//   }
  
//   label {
//   display: block;
//   margin-bottom: 5px;
//   font-weight: bold;
//   }
  
//   input[type="text"],
//   input[type="email"],
//   input[type="tel"] {
//   width: 100%;
//   padding: 5px;
//   margin-bottom: 10px;
//   }
  
//   /* Responsive Styles */
//   @media only screen and (max-width: 600px) {
//   .form-section {
//   padding: 5px;
//   }
//   }


.img-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  }


.foster-container {

  max-width: 1080px;

  margin: 0 auto;

  padding: 20px;

}

// .form-border {
//  // display: flex;
//   //flex-direction: column;
//   border: 2px solid #5b9bd5;
//  // padding: 50px;
//    width: 100%;
//    max-width: 1080px;
//   min-width: 390px;
//   // margin-right: 20px;
//   }


.foster-form-section {

  background-color: #ffd966;

  border: 2px solid #5b9bd5;

  padding: 10px;

  //margin-bottom: 20px;

  width: 100%;
  max-width: 1080px;
  min-width: 390px;

}

.label-input-field{

  // display: block;

  // margin-bottom: 5px;

  // font-weight: bold

  display: flex;
  align-items: center;
  // gap: 5px;
  width: 100%;
  max-width: 1080px;
  min-width: 390px;
}
.input-field{
  margin-right: 100px;
}

input[type="text"]{

  width: 100%;

  padding: 5px;

  //margin-bottom: 10px;

}

h2{
  font-size: large;
  font-family:sans-serif;
  font-weight: 300 bold;
}

h4{
  font-size: medium;
  font-weight: 100;
  font-family:sans-serif;
  
}

.select-option{
  
  width: 100%;
  margin-right: 100px;
 
}


input[type="email"]{

  width: 100%;

  padding: 5px;

  //margin-bottom: 10px;

}

input[type="tel"] {

  width: 100%;

  padding: 5px;

  //margin-bottom: 10px;

}


@media (max-width: 768px) {

  .form-section {

    padding: 15px;
    width: 100%;
max-width: 1080px;
min-width: 390px;
//margin-right: 20px;  

  }

}


@media (max-width: 480px) {

  .container {

    padding: 10px;

  }

  label {
    display: block;
    width: auto;
    }


  .fosterform-section {

    padding: 10px;

    margin-bottom: 15px;

  }

}

input[type="submit"] {
  margin-top: 20px;
  }

  .checkbox-heading {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}

.yn-checkbox {
display: flex;
justify-content: center;
}

.yn-checkbox span {
display: flex;
align-items: center;
margin-right: 10px;
}

.yn-checkbox label {
margin-left: 5px;
}

/* Prevent shrinking on smaller screens */
@media screen and (max-width: 1080px) {
.checkbox-heading,
.yn-checkbox {
flex-wrap: nowrap;
}
}

.req-update-action {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  }
  
   .close {
    margin-left: 45.33%;
  }
  
  .req-update-action .normal {
  margin-left: 15.33%;
  }

// .container {
//   margin-right: 20px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   }
  
//   .form-section {
//   background-color: yellow;
//   border: 2px solid blue;
//   padding: 10px;
//   margin-bottom: 20px;
//   width: 100%;
//   max-width: 1080px;
//   min-width: 390px;
//   }
  
//   h2 {
//   text-align: center;
//   }
  
//   label {
//   display: block;
//   margin-bottom: 5px;
//   font-weight: bold;
//   }
  
//   input[type="text"],
//   input[type="email"],
//   input[type="tel"] {
//   width: 100%;
//   padding: 5px;
//   margin-bottom: 10px;
//   }
  
//   input[type="submit"] {
//   margin-top: 20px;
//   }