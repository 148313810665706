@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

// @tailwind base;
// @tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";
@import "customcss/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
// @tailwind utilities;

* {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

.main-content {
    width: 100%;
    min-height: calc(100vh - 204px);
}

.footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-content p {
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;

        a {
            color: #eab500;
        }
    }

    .footer-social {
        display: flex;

        ul {
            list-style: none;
            display: flex;

            li {
                padding: 5px;
            }
        }
    }
}

.header-top {
    background: #1c4c7a;
    width: 100%;
}

.header-bottom {
    background: #3075b6;
}

.header-top {
    background: #1c4c7a;
    width: 100%;
    height: 45px;
}

.header-bottom {
    background: #3075b6;
    height: 45px;
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar ul {
    display: flex;
    align-items: center;
    list-style: none;
}

.navbar ul li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    padding: 5px 10px;
    font-size: 16px;
    display: block;
    line-height: 30px;
}

ul.main-menu li {
    position: relative;
    transition: all ease-in 300ms;
}

ul.main-menu li ul.sub-menu {
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #1c4c7a;
    width: auto;
    min-width: 250px;
    padding: 0;
    transition: all ease-in 300ms;
    z-index: 1;
}

ul.main-menu li ul.sub-menu li a {
    font-weight: 400;
}

ul.main-menu li:hover ul.sub-menu {
    display: block;
}

ul.main-menu li ul.sub-menu li:hover {
    background: #3075b6;
}

ul.main-menu li ul.sub-menu li a {
    line-height: 30px;
    display: block;
    width: 100%;
}

a.logo {
    margin-top: -25px;
}

.hero-text {
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
}

.hero {
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    background-position: center;
    display: flex;
    align-items: center;

    .container {
        display: flex;
        justify-content: flex-end;
    }
}

.hero-text-box {
    background: #000000b3;
    //  padding: 20px;
    border-radius: 15px;

    h6 {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }

    a {
        background: #1c4c7a;
        color: #fff;
        padding: 8px 16px;
        border-radius: 10px;
        text-decoration: none;
        float: right;
        //  margin-top: 15px;
    }
}

.hero-textalign {
    margin: 5px 0px 0px 0px !important;
}

.legendstyle {
    width: 45% !important;
}

a.logo {
    margin-top: -25px;
}

.logoauth img {
    margin-top: -53px;
    height: auto;
    width: 130px;
    background-color: white;
}

.cta {
    padding: 40px 0;

    .cta-btn {
        display: flex;
        justify-content: flex-end;

        a {
            background: #1c4c7a;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 30px;
            border-radius: 10px;
            text-decoration: none;
            color: #fff;
            font-size: 22px;
            line-height: 1.5;
        }
    }
}

.even-cal {
    width: 40%;
    padding-right: 50px;
}

.about .container {
    display: flex;
}

.about-content {
    width: 60%;
}

h2.c-heading {
    font-size: 36px;
    color: #3075b6;
    font-weight: 400;
    margin-bottom: 15px;
}

.even-cal ul {
    list-style: none;

    li {
        display: flex;
        background: #6caddf;
        border-radius: 5px;
        margin-bottom: 10px;

        .cal-date {
            background: #1c4c7a;
            display: flex;
            flex-direction: column;
            font-size: 25px;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border-radius: 5px 0px 0px 5px;
            color: #fff;
            text-transform: uppercase;

            b {
                font-size: 55px;
            }
        }
    }
}

.event-detail {
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;

    h3 {
        font-size: 24px;
    }

    span {
        font-size: 20px;
        line-height: 1.5;
        // display: flex;
        display: initial;

        img {
            margin-right: 8px;
        }
    }
}

.even-cal ul li:nth-child(even) .cal-date {
    background: #e4b63e;
}

.about {
    padding: 0 0 100px 0;
}

.about-content p {
    font-size: 18px;
    line-height: 30px;
}

.disclaimber {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0 0 0;
}

.disclaimber-box {
    border: 1px solid #1c4c7a;
    padding: 30px 60px;
    max-width: 640px;
}

.disclaimber-box h6 {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
}

.top-link {
    padding: 30px 0;

    .container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            color: #3075b6;
            font-size: 16px;
            padding: 0 5px;
        }
    }
}

h2.small-headings {
    color: #1c4c7a;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
}

.set-provider {
    padding: 30px 0;
}

form.provider-form {
    background: #f2f2f2;
    padding: 30px;
    display: flex;
    gap: 20px;
    align-items: flex-end;

    .ot-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 330px;

        input {
            height: 35px;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            padding: 10px;
        }

        label.form-label {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
}

.act-btn a {
    background: #1c4c7a;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

// common css

input[type="text"],
input[type="email"],
select,
input[type="date"],
input[type="file"],
input[type="time"] {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    padding: 7px 10px;
    background: #fff;
    margin-bottom: 10px;
    border: 1px solid #dedede;
}

textarea {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    padding: 7px 10px;
    background: #fff;
    margin-bottom: 14px;
    border: 1px solid #dedede;
}

.textareaheight {
    height: 110px !important;
}

.fontweight {
    font-weight: 500;
}

.helpheader {
    font-weight: 800;
    color: #3075b6;
    font-size: larger;
    margin-left: 10px;
    text-decoration: underline;
}

.helpimg {
    border: 3px solid rgb(85, 85, 85);
    width: 90%;
    margin-top: 20px;
    margin-left: 5%;
}

.fullpad {
    padding: 20px !important;
}

label {
    font-size: 14px;
    margin-bottom: 6px;
    width: 100%;
    display: block;
    color: #1c4c7a;
    // padding: 0 10px;
    line-height: 15px;
}

.grey-field textarea {
    min-height: 150px;
}

.sidebar ul a.active span {
    color: #3075b6;
    border-bottom: 1px solid #3075b6;
}

h3.children-dec {
    background: #3075b6;
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.normal {
    background: #1c4c7a;
    color: #fff;
    padding: 9px 20px !important;
    border-radius: 5px;
    text-transform: capitalize;
    width: auto;
    border: none;
    margin-left: 15px;
    font-size: 14px;
}

.close {
    background: #c93a3a;
    color: #fff !important;
    padding: 9px 20px !important;
    border-radius: 5px;
    text-transform: capitalize;
    width: auto;
    border: none;
    margin-left: 0;
    font-size: 14px;
}

.green {
    background: #219653;
    color: #fff;
    padding: 9px 20px !important;
    border-radius: 5px;
    text-transform: capitalize;
    width: auto;
    border: none;
    margin-left: 0;
    font-size: 14px;
}

.grey-btn {
    background: #4f4f4f;
    color: #fff;
    padding: 9px 20px !important;
    border-radius: 5px;
    text-transform: capitalize;
    width: auto;
    border: none;
    margin-left: 0;
    font-size: 14px;
}

.mc-form-action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.active {
    font-weight: 700;
}

.one-third {
    width: calc(33.3% - 12px);
}

.widthfull {
    width: 100%;
}

.tableborder {
    border: 1px solid;
}

.textpad {
    padding: 10px;
}

.headerpad {
    padding-top: 10px;
}

.centergap {
    padding-left: 5%;
}

.one-six {
    width: calc(16.66% - 12px);
}

.newlogofdailyevents {
    width: 100%;
    padding: 30px;
}

.label3 {
    width: 3%;
}

.label4 {
    width: 4%;
}

.label5 {
    width: 5%;
}

.label7 {
    width: 11%;
}

.label8 {
    width: 8%;
}

.label9 {
    width: 15%;
}

.label10 {
    width: 10%;
}

.label12 {
    width: 12%;
}

.label15 {
    width: 15%;
}

.label17 {

    width: 17%;
}

.label18 {

    width: 18%;
}

.label20 {
    width: 20%;
}

.label23 {
    width: 23%;
}

.label25 {
    width: 25%;
}

.label30 {
    width: 30%;
}

.label40 {
    width: 40%;
}

.label50 {
    width: 50%;
}

.label75 {
    width: 75%;
}

.labelpadtop {
    padding-top: 10px;
}

.labelpadtop2 {
    padding-top: 5px;
}

.headerpad {
    padding-top: 10px;
}

.textborder {
    margin: 5px;
    border: 1px solid;
    min-height: 80px;
}

.tableborderheight {
    border: 1px solid;
    min-height: 30px;
    padding: 2px;

}

.jc-sb {
    justify-content: space-between;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}


.mb-30 {
    margin-bottom: 30px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-0 {
    padding-top: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.fd-c {
    flex-direction: column !important;
}

.fr-w {
    flex-wrap: wrap !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.b-0 {
    border: none !important;
}

.url-field {
    width: 66.6%;
}

.d-flex {
    display: flex;
}

.ai-fe {
    align-items: flex-end !important;
}

.ai-fs {
    align-items: flex-start !important;
}

.ai-c {
    align-items: center !important;
}

.small-text {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
}

.req-update-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.form-row {
    display: flex;
    width: 100% !important;
}

.form-field {
    width: 100%;
}

span.plus-icon {
    padding-top: 25px;
    display: flex;
    margin-left: 20px;

    img {
        width: 34px;
    }
}

.checkbox-design b {
    font-size: 14px;
    line-height: 1;
    color: #1c4c7a;
}

.checkbox-design small {
    font-size: 10px;
    color: #1c4c7a;
    line-height: 15px;
}

.hidesection {
    display: none;
}

.one-half {
    width: calc(50% - 12px);
}

.fourthform {
    width: calc(25% - 12px);
}

.one-third {
    width: calc(33.33% - 8px);
}

.formrowalign {
    width: calc(50% - 12px) !important;
    display: flex;
    flex-direction: row;
}


.form-container {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: space-between;
}

.manage-content-action {
    display: flex;
    font-size: 12px;
    gap: 10px;
    color: #1c4c7a;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
}

.gap-20 {
    gap: 20px !important;
}

.gap-0 {
    gap: 0px !important;
}

.full-input {
    width: 100%;

}

.info-update-form .req-update-textarea {
    padding: 30px 0 !important;
}

.sfm-form .req-update-action {
    justify-content: flex-end !important;
}

.manage-parent-form .req-update-textarea textarea {
    margin: 0px 0;
}

.manage-parent-form .form-grey .req-update-action {
    justify-content: flex-end;
    margin-top: 20px;
}

.manage-anounce {
    gap: 10px;
    display: flex;

    .user-logout {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 45px;

        span {
            display: flex;
            align-items: center;
            color: #fff;

            b {
                margin-right: 5px;
            }

            img {
                margin-left: 5px;
            }
        }
    }
}

span.q-result {
    color: #27ae60;
    font-size: 12px;
    font-weight: 500;
}

.note-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 12px;

    ul {
        margin: 0;
        padding: 0 0 0 20px;
    }
}

.form-with-sidebar {
    display: flex;
    gap: 30px;

    form {
        width: calc(100% - 250px);
    }

    .form-note {
        width: 220px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 12px;
                line-height: 1.3;
                margin-bottom: 10px;
                color: #1c4c7a;
            }
        }
    }
}

p.note-p {
    font-size: 12px;
    line-height: 1.3;
    color: #1c4c7a;
}

.mobile-menu {
    display: none;
    flex-direction: column;
    gap: 5px;

    span {
        width: 24px;
        height: 3px;
        background: #fff;
        display: block;
    }
}

.parent-main {
    min-height: calc(100vh - 200px);
    padding: 80px 0;

    h2 {
        background: #3075b6;
        color: #fff;
        font-size: 20px;
        padding: 15px;
        text-align: center;
        margin-bottom: 30px;
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    ul {
        margin: 0px 0 0 0;
        list-style: none;
        border: 1px solid #f2f2f2;
        padding: 20px;

        li {
            margin-bottom: 10px;
            border: 1px solid #f2f2f2;
            padding: 15px;

            p {
                text-align: left;
            }

            a {
                display: flex;
                align-items: center;
                margin: 30px 0;
                font-size: 14px;
                color: #000;

                img {
                    max-width: 40px;
                }
            }
        }
    }
}

h4.blue-bg-heading {
    background: #3075b6;
    padding: 15px;
    text-align: center;
    color: #fff;
}

h3.blue-heading {
    color: #3075b6;
    text-align: center;
    margin: 30px 0;
}

.create-login {
    max-width: 600px;
    margin: 40px auto;

    span {
        display: flex;

        label {
            width: 250px;
            text-align: right;
            margin-top: 3px;
        }
    }
}

.login-action {
    display: flex;
    justify-content: flex-end;

    a {
        display: flex;
    }
}

.log-action {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.log-detail {
    max-width: 500px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.log-detail span label:first-child {
    width: 200px;
    text-align: right;
}

.reset-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.bg-blue {
    background: #1c4c7a !important;

    label {
        color: #fff;
    }
}

.login-form input {
    margin: 0;
}

.log-action a {
    font-size: 12px;
}

.show-pass {
    display: flex;

    label {
        color: #fff;
    }
}

button.yellow-btn {
    background: #eab530;
    border: none;
}

.user-logout {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
        display: flex;
        align-items: center;
        color: #fff;

        a {
            display: flex;
            align-items: center;
            color: #fff;
            height: 45px;
        }
    }
}

// ====================   Responsive Css  =====================

@media only screen and (max-width: 1200px) {
    ul.main-menu {
        display: none;
        position: absolute;
        right: 0;
        background: #1c4c7a;
        width: 100%;
        top: 89px;

        li ul.sub-menu {
            display: block;
            position: absolute;
            padding: 0 0 0 10px;
        }
    }

    .mobile-menu {
        display: flex;
    }

    ul.main-menu.open-menu {
        display: block;
    }

    .even-cal ul li .cal-date {
        font-size: 16px;

        b {
            font-size: 32px;
        }
    }

    .event-detail {
        h3 {
            font-size: 18px;
        }

        span {
            font-size: 16px;
        }
    }

    h2.c-heading {
        font-size: 24px;
    }

    .about-content p {
        font-size: 14px;
        line-height: 21px;
    }

    .disclaimber {
        margin: 50px 0 0 0;
    }

    .cta .cta-btn a {
        height: 44px;
        font-size: 16px;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 991px) {
    .brenda-detail ul {
        flex-wrap: wrap;

        li {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    .form-row.dandt {
        flex-wrap: wrap;
    }

    .about .container {
        display: flex;
        flex-direction: column-reverse;

        .about-content {
            width: 100%;
        }

        .even-cal {
            width: 100%;
            padding-right: 0;
            margin-top: 50px;

            ul {
                flex-wrap: wrap;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .disclaimber-box {
        padding: 30px 20px;
    }

    .footer-inner {
        flex-direction: column;

        .footer-social {
            flex-direction: column;
        }
    }

    .hero-text {
        width: 100%;
        padding-right: 0;
    }

    .hero-text-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .cta .cta-btn {
        justify-content: center;
    }

    .about {
        padding: 0 0 50px 0;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            text-align: center;
            margin-bottom: 15px;
        }
    }
}

button.fc-today-button.fc-button.fc-button-primary {
    text-transform: uppercase;
}

.yt-icon img {
    width: 24px;
}

.about-section-text-h4 {
    color: #6caddf;
    text-align: center;
}

.about-section-text-h5 {
    color: #6caddf;
}

th.preventative-measure {
    width: 180px;
}

th.beign-date {
    width: 120px;
}

h6.blue-color-heading {
    margin-bottom: 0 !important;
    font-weight: 500;
}

// =================  Hard Form  ================

.yn {
    width: 100%;
}

.hard-form {
    width: 100%;
    padding: 50px;
    border: 1px solid #ededed;
    margin-top: 80px !important;
    border-radius: 4px;
    max-width: 1170px;
    min-width: 100%;
    margin: 0 auto;

    h6 {
        color: #000;
        text-transform: none;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
        white-space: nowrap;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

.yn-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 21px;
    margin-bottom: 20px;
}

.yn-checkbox {
    display: flex;
}

.yn-checkbox span {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        min-width: 16px;
        height: 16px;
        border: 1px solid #000;
    }
}

.yn-checkbox span {
    display: flex;
    align-items: center;

    input[type="radio"] {
        min-width: 16px;
        height: 16px;
        border: 1px solid #000;
    }
}

.yn-checkbox label {
    margin: 0;
    font-size: 14px;
    color: #000;
}

.yn small {
    color: #828282;
}

.form-section-2,
.form-section-1 {
    .yn-field {
        justify-content: flex-start;
        gap: 15px;
    }
}

.form-section-2,
.form-section-3 {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ededed;
}

.hard-form {
    .input-divide {
        display: flex;
        width: 80%;
        gap: 15px;

        span {
            width: 50%;
            display: flex;
            align-items: center;

            input {
                border: none;
                border-bottom: 1px solid #dedede;
                border-radius: 0;
            }

            h6 {
                width: 100%;
            }
        }
    }
}

.checkbox-heading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 20px;

    h2 {
        margin: 0;
    }
}

.gernal-info-field {
    display: flex;
    //gap: 20px;

    .parent-name {
        display: flex;
        width: 50%;

        span {
            width: 100%;
        }
    }
}

.gender {
    width: 50%;
    display: flex;
    gap: 20px;

    span {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.gernal-info-field span input {
    border: none;
    border-bottom: 1px solid #dedede;
    border-radius: 0;
    width: 65%;
}

.parent-name {
    span {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    h6 {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
    }
}

.parent-name-three {
    display: flex;
    width: 33.3%;

    span {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

ul.immediate-assessment li {
    font-size: 14px;
    line-height: 21px;
}

.i-agree .g-background {
    margin: 0;
    padding: 20px 15px;
}

small.red-text {
    color: #c93a3a !important;
}

.i-agree {
    span.check-option {
        display: flex;
        margin: 7px 0;
        grid-gap: 12px;
        gap: 0px;
    }

    input[type="text"] {
        margin-bottom: 5px;
    }
}

.i-agree small {
    font-size: 12px;
}

ul.immediate-assessment {
    padding-left: 15px;
}

.gernal-info-field.f-w .parent-name {
    width: 100%;
}

table.pdf-form {
    margin-bottom: 30px;

    tr:nth-child(even) {
        background-color: #fff;
    }

    th {
        font-size: 14px;
        color: #000;
    }
}

table.pdf-form,
table.pdf-form td,
table.pdf-form th {
    border-collapse: collapse;
    border: 1px solid black;
    height: 34px;
}

.form-section-1 {
    margin-bottom: 50px;
    margin-left: 50px;
}

.check-input input {
    border: none;
    border-bottom: 1px solid #dedede;
    border-radius: 0;
}

.check-input {
    .yn-checkbox label {
        width: auto;
        white-space: nowrap;
    }
}

.form-header {
    border-bottom: 1px solid #dedede;
    margin-bottom: 50px;
}

h3.heading-foster-parent {
    display: flex;
    font-size: 31px;
    text-align: center;
    margin-top: 30px;
    margin-left: 350px;
}

.fgdzwy {
    margin: 0 4px;
    display: none;
}

.iyunOG {
    margin: 0px 4px;
    display: none;
}

.dragdrop {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: start;

    .action-item {
        display: flex;
        width: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            background: #1c4c7a;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            border-radius: 4px;
        }
    }

    .selected-item div,
    .deselected-item div {
        width: 100%;
        border: 1px solid #efefef;
        border-radius: 4px;
        min-height: 300px;
    }
}

.selected-item,
.deselected-item {
    width: calc(50% - 20px);
    min-height: 300px;
}

.selected-item span,
.deselected-item span {
    display: flex;
    margin: 0 0 14px 0;
    align-items: center;
}

.selected-item span label,
.deselected-item span label {
    font-size: 16px;
    margin: 0;
}

.disabledCursor {
    cursor: default;
    background-color: #6086ab;
}

.desc-add-to-caraousel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.desc-add-to-caraousel span {
    align-items: center;
    display: flex;
}

.desc-add-to-caraousel span label {
    margin: 0;
    white-space: nowrap;
}

.desc-add-to-caraousel label {
    margin: 0;
}

.three-buttons {
    display: flex;
}

.hiddenPrintable {
    display: none;
}

//calender add time pm am
.fc-event-time:after {
    content: "m";
}

// add more space
.fc-day-grid-event .fc-content {
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.fc-event-time,
.fc-event-title {
    padding: 0 1px;
    float: left;
    clear: none;
    margin-right: 10px;
    word-wrap: break-word;
}

.fc-daygrid-event {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

// .fc-daygrid-day-bottom{
// width: 200px;
// }

.fc-daygrid-event-harness .fc-event {
    overflow-wrap: break-word;
    word-wrap: break-word;
    // min-height: 100px;
}

// .fc-event{
// // height:50px;
// // overflow-x: auto;
// overflow-wrap: break-word;
// word-wrap: break-word;

// }

// .fc-daygrid-block-event{
//      width: 200px;
//      overflow: auto;
//     height:50px
// }

// .fc-event-title .fc-sticky{
//     // word-wrap: break-word;
//     // overflow-wrap: break-word;
//     overflow: auto !important;

// }

// .fc-event-main .fc-h-event .fc-event-title{
//     overflow-wrap: break-word;
//     word-wrap: break-word;
//     overflow: visible;
//     margin-left: auto;
//     margin-right: auto;
//     width: auto;

// }

// .fc-event-title-container{
//     overflow-wrap: break-word !important;
//     word-wrap: break-word;
//     overflow: visible;
//     margin-left: auto;
//     margin-right: auto;
//     width: auto;

// }

.fc-h-event .fc-event-title {
    overflow: auto !important;
    // overflow-wrap: break-word !important;
    // word-wrap: break-word !important;
}

.mobile-menu {
    cursor: pointer;
}

// New page CSS
.tab-detail.full-page-view {
    width: 100%;
    padding: 0;
    max-width: 1170px;
    margin: 0 auto;
}

.tab-detail.full-page-view {
    padding: 0;
}

.form-row.Announcement-filter {
    align-items: center;
}

.form-row.Announcement-filter a.normal {
    margin-bottom: 10px;
    margin-left: 10px;
}

.form-row.Announcement-filter {
    margin-bottom: 15px;
    border-bottom: 1px solid #ededed;
}

.announcement-list {
    display: flex;
    column-gap: 15px;
    margin-bottom: 30px;
    cursor: pointer;
}

.announcement-list .imageSection {
    border: 2px solid #ededed;
    border-radius: 4px;
    height: 100px;
}

.announcement-list .imageSection img {
    width: 150px;
    height: 100px;
    object-fit: unset;
    display: block;
}

.announcement-list h1 {
    font-size: 16px;
    color: #333;
    line-height: 28px;
}

.announcement-list h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.Announcement-filter-row {
    display: flex;
    align-items: center;
}

.Announcement-filter-row label {
    width: auto;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}

.Announcement-filter-area {
    display: flex;
    align-items: center;
}

form.child-case-form.event-page {
    flex-direction: column;
}

form.child-case-form.event-page a {
    margin-top: 0px;
    height: 34px;
}

.form-row.child-case-form-row {
    column-gap: 15px;
}

.form-row.child-case-form-row .action-btn {
    padding: 20px 0;
}

.event-detail-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    cursor: pointer;
}

.event-detail-list h3 {
    font-size: 18px !important;
    margin: 0 !important;
    line-height: 24px;
    margin-bottom: 0;
}

.event-detail-list small {
    color: #999;
    line-height: 20px;
    margin-bottom: 10px;
}

.event-detail-list a {
    margin-top: 10px !important;
    display: inline-block;
}

.event-detail-list b {
    font-size: 14px;
    line-height: 24px;
    margin-top: 5px;
}

.parag {
    align-content: baseline !important;
    align-items: baseline;
    text-align: left !important;
}

.footer-social ul {
    border: none;
}

.footer-social li {
    border: none !important;
}

img.footer-logoo {
    width: 140px;
    margin-right: 62px;
    margin-top: 28px;
}

.footer-social a {
    margin: 4px 0 !important;
}

@media screen and (max-width: 1024px) {
    .one-third {
        width: 100%;
    }

    .form-row.dandt.fdc {
        flex-direction: column;
        row-gap: 0;
    }

    .children-tab span {
        cursor: pointer;
    }
}



@media screen and (max-width: 991px) {
    .top-link .container a {
        font-size: 12px;
    }

    ul.main-menu.open-menu {
        z-index: 2;
    }

    .navbar ul li a {
        font-size: 14px;
        line-height: 24px;
    }
}

@media screen and (max-width: 991px) {
    .top-link .container {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .top-link {
        padding: 15px 0px;
    }
}

// @media screen and (max-width: 1042px) {

//     .one-third {
//         width: calc(100% - 0px);
//     }

//     .one-half {
//         width: calc(100% - 0px);
//     }
//     .label3{
//         width:100%;
//     }
//     .label5{
//         width:100%;
//     }
//     .label7{
//         width:100%;
//     }
//     .label8{
//         width:100%;
//     }
//     .label10{
//         width:100%;
//     }
//     .label15{
//         width:100%;
//     }
//     .label18{

//         width:100%;
//     }
//     .label20{
//         width:100%;
//     }
//     .label25{
//         width:100%;
//     }
//     .label40{
//         width:100%;
//     }
//     .label50{
//         width:100%;
//     }
//     .label75{
//         width:100%;
//     }
// }

@media screen and (max-width: 600px) {
    .form-with-sidebar .form-note {
        width: 100%;
    }

    .form-with-sidebar form {
        width: calc(100% - 0px);
    }

    .form-with-sidebar {
        flex-direction: column;
    }

    .form-row.dandt {
        row-gap: 0;
    }

    .one-fifth {
        width: 100%;
    }

    .form-row.rg {
        row-gap: 0px;
    }

    form.provider-form {
        flex-direction: column;
        row-gap: 0;

        .act-btn a {
            width: auto;
            height: auto;
            font-size: 12px;
            font-weight: normal;
            padding: 10px 20px;
        }
    }

    .select-search {
        width: auto !important;
    }

    form.provider-form .act-btn {
        margin: 15px 0 0 0;
    }

    span.narea {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

#divToPrint {
    position: absolute;
    left: -1600px;
    pointer-events: none;
}

form.children-doc-form .ifOHjV {
    display: block !important;
}

[data-column-id="1"],
[data-column-id="2"],
[data-column-id="3"],
[data-column-id="4"],
[data-column-id="5"],
[data-column-id="6"],
[data-column-id="7"],
[data-column-id="8"],
[data-column-id="9"],
[data-column-id="10"] {
    white-space: unset !important;
}

.action-btn {
    display: flex;
    justify-content: flex-end;
    grid-gap: 15px;
    gap: 310px;
    padding: 30px 0;
}

.cut-text {
    // text-overflow: ellipsis;
    // overflow: hidden; 
    // width: 300px; 
    // height: 5em; 
    // white-space: nowrap;
    width: 400px;
    overflow: line;
    display: inline-block;
    text-overflow: initial;
    white-space: nowrap;
}

//caraousel css 

.carousel .slide img {
    max-height: 500px;
    /* change this to whatever you want */
    width: auto;
    width: 100%;
}

.fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: column !important;
}

.fc .fc-daygrid-day-top {
    padding: 0px
}

.multiple-value-text-input-item-container {
    line-height: 20px !important;
}



.carousel .slide .legend {
    background: black !important;
    opacity: 1.15 !important;

    .hero-text-box {
        background-color: black !important;
        opacity: 5 !important;

    }
}


.carousel .slide img {
    height: 440px;
    object-fit: cover;
}

.formrowalign2 {
    display: flex;
    width: 100% !important;
    flex-direction: row;

}

.rowalign {
    display: flex;
    flex-direction: row;
}




@media screen and (max-width: 767px) {
    .incident-type {
        //width: calc(100% - 0px);
        flex-wrap: wrap;
    }

    .rowalign1 {
        list-style: none;

        flex-wrap: wrap;
        grid-gap: 10px;
        gap: 10px;
        padding: 5px;
    }

}

.identifytext {
    font-size: small;
    margin-top: 9px;
    font-style: italic;
}

.input_text {
    border: none !important;

}

.italictext {
    font-style: italic !important;
    font-size: small !important;
    text-transform: lowercase !important;
}

.headtext {
    font-size: smaller;
    font-weight: bold;
}

.notificationalign {
    .textfont {

        font-weight: 500;
        font-size: large;
        color: #2a537b;
        margin-bottom: 10px;
    }

    p {
        text-align: center;
        font-size: large;
        color: #2a537b;
    }

    table {
        border: 1px solid !important;
        margin-top: 5px;

        input {
            border: none !important;
            border-bottom: 1px solid !important;
        }

        th {
            border: 1px solid !important;
            padding: 0px 0px 0px 12px !important;
            font-size: 17px !important;
            height: 40px;
            font-weight: 500;
        }

        td {
            border: 1px solid !important;
            padding: 7px !important;

            input {
                margin-bottom: 0px !important;
            }
        }

        tr {
            border: 1px solid !important;
        }

        .tableinputbg {
            input {
                background-color: #E0E0E0 !important;
            }
        }
    }
}


#divToPrint {
    font-size: 20px;
}

.increasedFont {
    font-size: 18px;
}

$spacer: 1rem;

.m-1 {
    margin: ($spacer * .25) !important
}

.m-2 {
    margin: ($spacer * .5) !important
}

.m-3 {
    margin: ($spacer * 1) !important
}

.m-4 {
    margin: ($spacer * 1.5) !important
}

.m-5 {
    margin: ($spacer * 3) !important
}

.ml-1 {
    margin-left: ($spacer * .25) !important
}

.ml-2 {
    margin-left: ($spacer * .5) !important
}

.ml-3 {
    margin-left: ($spacer * 1) !important
}

.ml-4 {
    margin-left: ($spacer * 1.5) !important
}

.ml-5 {
    margin-left: ($spacer * 3) !important
}

.pl-1 {
    padding-left: ($spacer * .25) !important
}

.pl-2 {
    padding-left: ($spacer * .5) !important
}

.pl-3 {
    padding-left: ($spacer * 1) !important
}

.pl-4 {
    padding-left: ($spacer * 1.5) !important
}

.pl-5 {
    padding-left: ($spacer * 3) !important
}

.delete-btn {
    color: white !important;
    background-color: red;
    width: 60px;
    padding: 4px 10px;
    border-radius: 5px;
}

.margin-top {
    margin-top: 38.5px;
}

.mb-1 {
    margin-bottom: ($spacer * .25) !important
}

.mb-2 {
    margin-bottom: ($spacer * .5) !important
}

.mb-3 {
    margin-bottom: ($spacer * 1) !important
}

.mb-4 {
    margin-bottom: ($spacer * 1.5) !important
}

.mb-5 {
    margin-bottom: ($spacer * 3) !important
}

/* Modal Container */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal Dialog */
.modal-dialog {
    max-height: 90vh;
    /* Allows the modal to be scrollable */
    display: flex;
    flex-direction: column;
}

/* Modal Content */
.modal-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    /* Prevents content overflow outside modal */
}

/* Modal Header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    background: white;
    border-radius: 6px 6px 0px 0px;
}

/* Modal Title */
.modal-title {
    font-size: 1.25rem;
    font-weight: 500;
}

/* Close Button */
.close-btn-modal {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Modal Body */
.modal-body {
    flex-grow: 1;
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 85vh;
    /* Adjust height based on your preference */
    border-radius: 0px 0px 6px 6px;
}

/* Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

/* Bootstrap Button */
.btn-modal {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

// .btn-primary:hover {
//     background-color: #0056b3;
// }

/* Animation Effects */
.fade {
    transition: opacity 0.15s linear;
}

.show {
    opacity: 1;
}

.float-right {
    float: right;
}
.normal:hover {
    cursor: pointer;
}