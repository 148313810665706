.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
    }
}

form.myprofile-form .form-row {
    display: flex;
    gap: 30px;
    .ot-field {
        display: flex;
        flex-direction: column;
        width: calc(33.3% - 20px);
        input {
            width: 100%;
            height: 36px;
            border-radius: 4px;
        }
        label {
            font-size: 14px;
            margin-bottom: 5px;
            width: 100%;
        }
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
}




h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 20px;
    display: inline-block;
}

.imp-note {
    background: #f2f2f2;
    padding: 15px;
    margin-top: 50px;
    ol {
        padding: 10px 20px;
        li {
            font-size: 14px;
            line-height: 1.5;
        }
    }
    
}






.sidebar ul a span:hover {
    color: #1c4c7a;
}

.tabui ul {
    display: flex;
    list-style: none;
}


.tabui ul li:not(:last-child) {
    border-right: 1px solid #1c4c7a;
    padding-right: 10px;
}

.tabui ul li a {
    color: #3075b6;
    padding: 5px 0;
    display: inline;
    font-size: 14px;
}

.tabui ul li:not(:first-child) {
    padding-left: 10px;
}

.tabui {
    margin-bottom: 40px;
}


.ot-field input, .ot-field select {
    height: 36px;
    width: 100%;
    margin-bottom: 14px;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 10px;
    background: #ffffff;
}

.form-tab-1.m-bottom {
    margin-bottom: 40px;
}

form.myhouse-hold a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0;
    display: inline-block;
    font-size: 14px;
}

form.myhouse-hold table {
    margin-bottom: 20px;
}

form.myhouse-hold table td {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
}


// Responsive Css

@media screen and (max-width: 991px) {
    form.myprofile-form .form-row .ot-field {
        width: calc(50% - 20px);
    }
    form.myprofile-form .form-row .ot-field label {
        font-size: 12px;
    }
    form.myprofile-form .form-row {
        flex-wrap: wrap;
    }
    form.myprofile-form .form-row {
        gap: 30px;
        row-gap: 0;
    }
  }

@media screen and (max-width: 767px) {
    form.myprofile-form .form-row .ot-field {
        width: calc(100% - 20px);
        
    }
    .tabui ul li:not(:first-child) {
        padding-left: 0;
    }
    .tabui ul {
        flex-direction: column;
    }
    .tabui ul li:not(:last-child) {
        border-right: 0px solid #1c4c7a;
        padding-right: 10px;
    }
}