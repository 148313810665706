#test
{
    color:red;
}

.container {
    width: 100%;
    max-width: 1400px;
    padding: 0 15px;
    margin: 0 auto;
}

.footer {
    background: #3075B6;
    padding: 30px 0 40px;
}