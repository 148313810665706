.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}


.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
    }
}

form.myprofile-form .form-row {
    display: flex;
    gap: 30px;
    .ot-field {
        display: flex;
        flex-direction: column;
        width: calc(33.3% - 20px);
        input {
            width: 100%;
            height: 36px;
            border-radius: 4px;
        }
        label {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
}


form.myprofile-form {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 50px;
    padding-bottom: 50px;
}



form.myprofile-form {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 30px 0;
}

.action-btn a {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 14px;
}

a.close-btn {
    background: #C93A3A;
}