.videos-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 30px;
    .video-detail {
        width: calc(50% - 20px);
        border-bottom: 1px solid #3075b6;
        padding-bottom: 30px;
        p {
            font-size: 14px;
            line-height: 21px;
            height: 85px;
            overflow: hidden;
        }
        h3 {
            font-size: 14px;
            color: #3075B6;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
    }
}

    select.select-training  {
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: transparent;
        width: auto;
        height: 36px;
        border-radius: 4px;
        min-width: 240px;
    }

    .read-more span {
        color: #3075b6;
        font-size: 14px;
        font-weight: 700;
    }

    .read-more.read-more-open {
        p {
            height: auto;
    }
    span {
        display: none;
    }
}

.video-page {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0;
    gap: 20px;
    span {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        margin-left: 20px;
    }
}


// Responsive CSS
@media screen and (max-width: 767px) { 
    .sidebar.dnone {
        display: none;
    }
    .pagewithsidebar-inner .tab-detail {
        padding: 0 0 0 0px;
    }
    .children-section img {
        max-width: 36px;
    }
    .videos-grid .video-detail {
        width: calc(100% - 0px);
        padding-bottom: 30px;
    }
    .video-page {
        margin-bottom: 50px;
    }
    .tab-detail.pl {
        padding: 0 0 0 20px!important;
    }
}
