
.search-tab {
    width: 100%;
    form {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .search-button {
            display: flex;
            position: relative;
            button {
                height: 34px;
                border: navajowhite;
                display: flex;
                align-items: center;
                padding: 0;
                width: 34px;
                position: absolute;
                right: -2px;
                top: 0px;
                background: transparent;
                img {
                    height: 30px;
                }
            }
            input[type="text"] {
                padding-right: 36px;
                margin: 0;
            }
        }
    }
    a {
        width: auto;
        font-size: 14px;
        margin-left: 15px;
        line-height: 1;
    }
}


td.table-action {
    width: 140px;
    a {
        margin: 0 5px;
    }
}

.add-childrendata {
    padding-bottom: 50px;
}