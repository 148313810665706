.account-acitivity-form {
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 0px;
        th {
            text-align: left;
            font-size: 12px;
            color: #1C4C7A;
            font-weight: 400;
            padding: 14px 15px;
        }
        td {
            padding: 10px 15px;
            font-size: 14px;
            font-weight: 400;
            vertical-align: middle;
        }
    }
    .table-act {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-right: 0px;
        margin-top: 5px;
    }
    .req-update-textarea {
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        border-bottom: 1px solid #e8e8e8;
        textarea {
            min-height: 150px;
            margin: 20px 0;
        }
        label {
            color: #1C4C7A;
            font-size: 12px;
        }
        .req-update-action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }  
   
}





tr:nth-child(even) {
    background-color: #E0E0E0;
  }


.account-acitivity-top {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }  
}

// Responsive CSS
@media screen and (max-width: 767px) { 
    .account-acitivity-top img {
        max-width: 36px;
    }
    .account-acitivity-top {
        font-size: 18px;
    }
}