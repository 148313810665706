.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    position: relative;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    .pagealignpad {
        width: 100% !important; 
        padding: 50px !important;
      }
    
}


// .pagewithsidebar-inner {
//     display: flex;
//     width: 100%;
//     padding-bottom: 80px;
//     position: relative;
//     .pagealignpad {
//       width: 100% !important; 
//       padding: 30px !important;
//     }
    
// }


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        width: 200px;
        margin-left: 20px;
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 50px;
}

h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.m-profile {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    th {
        text-align: left;
        font-size: 12px;
        color: #1C4C7A;
        font-weight: 400;
        padding: 14px 15px;
    }
    td {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
    }
}

tr:nth-child(even) {
    background-color: #E0E0E0;
  }
.table-act {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
}

.pagesubtitle p {
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    line-height: 21px;
    margin-top: 20px;
    color: #EB5757;
}

.table-act img {
    width: 24px;
}


form.attach-doc-form .form-row {
    display: flex;
    gap: 30px;
    .ot-field {
        display: flex;
        flex-direction: column;
        width: calc( 33.3% - 20px);
        margin-bottom: 10px;
        input {
            width: 100%;
            height: 36px;
            border-radius: 4px;
        }
        label {
            font-size: 12px;
            margin-bottom: 5px;
            width: 100%;
            color: #1C4C7A;
        }
    }
}

.submit {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    width: 120px;
    border: none;
    font-size: 14px;
}


.req-update-textarea {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    border-bottom: 1px solid #e8e8e8;
}

.req-update-textarea textarea {
    min-height: 150px;
    margin: 20px 0;
}

.req-update-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.req-update-textarea label {
    color: #1C4C7A;
    font-size: 12px;
}

.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .close-button {
    border: none;
    display: inline-block;
    padding: 4px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-size: 1.25em;
  }
  
  .topright {
    position: absolute;
    right: 0;
    top: 0
  }

  .btn-bg{
    background-color:#000;color:#fff;
  }

 .iframeWidht{
    width: 950px;
    height: 600px;
 }

 @media only screen and (max-width: 600px) {
    .iframeWidht{
        width: 350px;
        height: 600px;
     }
 }