.Critical-unusual {
    display: flex;
    align-items: flex-start;
    font-size: 27px;
    text-transform: uppercase;
    color: #3075B6;
    font-weight: 700;
    img {
        margin-right: 10px;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    position: relative;
    .pagealignpad {
      width: 100% ; 
      padding: 30px;
    }
    
}

a.blue-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 14px;
}
a.red-btn {
    background: #C93A3A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 14px;
    margin-left: 10px;
}

.select-incident .form-row {
    display: flex;
    select {
        height: 36px;
        width: 100%;
        margin-bottom: 0;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: transparent;
        margin-right: 20px;
    }
   
    
}

.form-row{
    display: flex;
    flex-wrap: wrap;
    
}

.form-row.dandt {
    display: flex;
    gap: 17px;
    margin-top: 48px;
    input[type="date"] {
        height: 45px;
        width: 100%;
        margin-bottom: 5px;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: transparent;
    }
    input[type="time"] {
        height: 45px;
        width: 100%;
        margin-bottom: 14px;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: transparent;
    }
    select {
        height: 40px;
        width: 100%;
        margin-bottom: 14px;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: transparent;
    }
    small {
        color: #007dc3;
    }
    
    
}
.form-row{
    small {
        color: #007dc3;
       // padding-left: 10px;
    }
}

.one-fourth {
    width: calc(25% - 18px);
}


.g-background {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    select {
        height: 36px;
        width: 100%;
        margin-bottom: 0;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 10px;
        background: #fff;
        margin-right: 20px;
    }
    .form-row {
        display: flex;
        flex-wrap: wrap;
       // gap: 20px;
        width: 100%;
        .one-half {
            width: calc(50% - 15px);
        }
     }
}



.note {
    font-size: 12px;
    color: #1C4C7A;
    line-height: 18px;
    ol {
        padding: 15px 0 0 13px;
    }
}
.action{
    font-size: 13px;
    color: #1C4C7A;
    p{
       font-weight: 500;
        font-size: large;
        color: #2a537b;
        margin-bottom: 10px;
    }
  
    ol{
        margin: 10px 0px 0px 20px;
    }
    ul{
        margin: 0px 0px 0px 20px;
    }
}


.form-row.v-pass {
    display: flex;
    color: #1c4c7a;
    align-items: center;
    p {
        font-size: 14px;
        margin-right: 20px;
    }
    span {
        display: flex;
        label {
            margin: 0;
        }
    }
}



.form-row.v-pass {
    display: flex;
    color: #1c4c7a;
    align-items: center;
    p {
        font-size: 14px;
        margin-right: 20px;
    }
}

.form-row.v-pass span {
    display: flex;
}

.form-row.v-pass span label {
    margin: 0;
}

.incident-type h6 {
    color: #1C4C7A;
    font-size: 14px;
    font-weight: 700;
}

.incident-type {
    margin-top: 40px;
}

.incident-type ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px;
}

.incident-type ul li {
    width: calc(50% - 15px);
    display: flex;
    label{
        padding-top: 10px;
    }
}


.add-cmt {
    margin-top: 40px;
    textarea {
        margin: 0px !important;
        height: 150px;
        overflow-y: auto;
    }
    h6{
        color: #1C4C7A;
        font-size: 14px;
        font-weight: 700;
    }
}
.add-cmt2 {
    textarea {
        min-height: 70px !important;
        margin-bottom: 30px;
        overflow-y: auto;
        height: 70px;
    }

}

.inline-flex {
    display: flex;
}
.actiontextarea{
    display:flex;
    flex-direction: row;
    border: 1px solid #dedede;
    label{
        margin-top: 12px;
        padding:0px 0px 0px 10px!important;
 
    }
   textarea{
    margin-top: 12px !important;
    padding: 0px !important;
   // margin: 0px !important;
    border: none;
    height: 30px;
   }

}
.actiontextarea1{
    display:flex;
    flex-direction: row;
    border: 1px solid #000000;
    margin: 5px ;
    label{
        margin-top: 12px;
        padding:0px 0px 0px 10px!important;
 
    }

   .textborder1{
    margin-top: 12px !important;
    padding: 0px !important;
   // margin: 0px !important;
    border: none;
    height: 30px !important;

   }
}