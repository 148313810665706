// .main 
// {
//   width: 100%;
  
// }
// .container1{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     //align-items: center;
// }

// .section-4{
//     flex: 1 1 100%;
//     min-height: 100px;
// }

// .section-5{
//     flex: 1 1 100%;
//     min-height: 100px;
// }
// .section-6{
//     flex: 1 1 100%;
//     min-height: 70px;
// }

// @media (min-width:768px) {
   
//     .section-4{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
   
   
//     }
   
//     .section-5{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
//     }
   
//    .section-6{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
//     }
   

   
//    } 

/* Styles for responsiveness */
.tab-detail-bottom {
    display: contents;
    flex-wrap: wrap;
    }
    
    .power-small-text,
    .power-form-row,
    .power-one-half,
    .powerone-half,
    .power-one-third,
    .power-req-update-textarea {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    }
    
    /* Media queries for mobile responsiveness */
    @media (min-width:768px ) and (max-width: 1080px) {
    .power-one-half,
    .powerone-half,
    .power-one-third {
    width: 100%;
    display: flex;
    }
    }
    
    /* Additional styling */
    .power-form-row.dandt {
    display: flex;
    flex-wrap: wrap;
    }
    
    .power-one-half,
    .powerone-half,
    .power-one-third {
    padding-right: 10px;
    }
    
    /* Adjustments for smaller screens */
    @media (max-width: 390px ) {
    .power-one-half,
    .powerone-half,
    .power-one-third {
    width: 100%;
    padding-right: 0;
    display: flow-root;
    }
    }
    

   



