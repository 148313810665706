// .main 
// {
//   width: 100%;
  
// }

// .container{

//     display: flex;
   
//     flex-wrap: wrap;
   
//     justify-content: space-between;
   
//     //align-items: center;
   
//    }
   
   
   
   
//    .section-4{
   
//     flex: 1 1 100%;
   
//     min-height: 100px;

   
//    }
   
   
   
   
//    .section-5{
   
//     flex: 1 1 100%;
   
//     min-height: 100px;
   
//    }
   
//    .section-5{
   
//     flex: 1 1 100%;
   
//     min-height: 100px;
   
//    }
   
   
   
   
//    @media (min-width:768px) {
   
//     .section-3{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
   
   
//     }
   
//     .section-4{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
//     }
   
//    .section-5{
   
//     flex:1 1 33.33%;
   
//     flex-direction: column;
   
//     }
   

   
//    } 



   /* Container styles */
//    .form-power .dant {
//   margin-bottom: 10px;
//   display: flex;
//   grid-gap: 17px;
//   gap: 17px;
  
//     }
// .form-container {
//   max-width: 1080px;
//   min-width: 390px;
//   margin: 0 auto;
//   padding: 20px;
//   }
  
  /* Form header styles */
  // .form-header {
  // text-align: center;
  // margin-bottom: 20px;
  // }
  
  // .form-header img {
  // width: 100%;
  // max-width: 200px;
  // margin-bottom: 10px;
  // }
  
  /* Form input styles */
  // .form-input {
  // margin-bottom: 10px;
  // display: flow-root;
  // grid-gap: 17px;
  // gap: 17px;
  // }
  
  // .form-input label {
  // display: block;
  // margin-bottom: 5px;
  // }
  
  // .form-input input,
  // .form-input textarea {
  // width: 100%;
  // padding: 5px;
  // border: 1px solid #ccc;
  // border-radius: 4px;
  // }
  
  /* Form button styles */
  // .form-buttons {
  // text-align: center;
  // margin-top: 20px;
  // }
  
  // .form-buttons a {
  // display: inline-block;
  // margin-right: 10px;
  // padding: 10px 20px;
  // background-color: #ccc;
  // color: #fff;
  // text-decoration: none;
  // border-radius: 4px;
  // }
  
  /* Form footer styles */
  // .form-footer {
  // text-align: center;
  // margin-top: 20px;
  // }
  
  /* Media query for mobile responsiveness */
  // @media only screen and (max-width: 1080px) {
  // .form-container {
  // max-width: 100%;
  // padding: 10px;
  // }
  // .form-rows .dant {
  //   display: flex;
  //   max-width: 100%;
  //   padding: 10px;
  //   }
  // }




  // @media only screen and (max-width: 390px) {
  
  //   .form-power .dant {
  //   display: flex;
  //   max-width: 100%;
  //   padding: 10px;
  //   }
  //   input[type=text] {
  //     width: 100%;
  //     padding: 5px;
  // } 
  // }
    
  /* Styles for responsiveness */
.tab-detail-bottom {
  display: contents;
  flex-wrap: wrap;
  }
  
  .power-small-text,
  .power-form-row,
  .power-one-half,
  .powerone-half,
  .power-one-third,
  .power-req-update-textarea {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2px;
  display: flex;
  }
  
  /* Media queries for mobile responsiveness */
  @media (min-width:768px ) and (max-width: 1080px) {
  .power-one-half,
  .powerone-half,
  .power-one-third {
  width: 100%;
  display: flex;
  }
  }
  
  /* Additional styling */
  .power-form-row.dandt {
  display: flex;
  flex-wrap: wrap;
  }
  
  .power-one-half,
  .powerone-half,
  .power-one-third {
  padding-right: 10px;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 390px ) {
  .power-one-half,
  .powerone-half,
  .power-one-third {
  width: 100%;
  padding-right: 0;
  display: flow-root;
  }
  }
  