.hero-section {
    background: #EAB500;
    padding: 80px 0;
}

.hero-text {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    float: left;
    padding-right: 50px;
    h2 {
        font-size: 40px;
        color: #1C4C7A;
        margin-bottom: 20px;
    }
    p {
        color: #fff;
        font-size: 20px;
        line-height: 1.7;
        margin-bottom: 30px;
    }    
}

.login-form {
    width: 25%;
    float: left;
}

.hero-section .container {
    display: flex;
}


.login-form {
    background: #1c4c7a;
    border-radius: 4px;
    padding: 32px 16px;
}

.login-form h3 {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
}

.log-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.log-field input {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background: #fff;
    box-shadow: none;
    border: none;
}

.log-field label {
    font-size: 12px;
    color: #fff;
    margin-bottom: 5px;
    font-weight: 400;
}



.hero-section {
    background: #EAB500;
    padding: 80px 0;
}

.hero-text {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    float: left;
    padding-right: 50px;
}

.login-form {
    width: 25%;
    float: left;
}

.hero-section .container {
    display: flex;
}

.hero-text h2 {
    font-size: 40px;
    color: #1C4C7A;
    margin-bottom: 20px;
}

.hero-text p {
    color: #fff;
    font-size: 20px;
    line-height: 1.7;
    margin-bottom: 30px;
}

.login-form {
    background: #1c4c7a;
    border-radius: 4px;
    padding: 32px 16px;
}

.login-form h3 {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
}

.log-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.log-field input {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background: #fff;
    box-shadow: none;
    border: none;
    padding: 5px 15px;
}

.log-field label {
    font-size: 12px;
    color: #fff;
    margin-bottom: 5px;
    font-weight: 400;
}

a.yellow-btn {
    background: #eab500;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 10px;
}

.log-action {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.log-action a {
    color: #fff;
    text-transform: capitalize;
}

.show-pass {
    color: #fff;
    font-size: 12px;
}


.show-pass input[type="checkbox"] {
    margin-right: 5px;
    width: 14px;
    height: 14px;
}

// about section

.whoweare-section {
    padding: 80px 0;
    p {
        font-size: 18px;
        line-height: 1.5;
    }
    .container {
        display: flex;
        .whoweare-section-left {
            padding-right: 25px;
        }
        .whoweare-section-right {
            padding-left: 25px;
        }
        
    }
}

.container.img__cont img {
    max-width: 100%;
}


// Responsive Css

@media only screen and (max-width: 991px) {
    .hero-text {
        width: 60%;
    }
    .login-form {
        width: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .container.img__cont img {
        max-width: 100%;
    }
    
    .hero-text, .login-form {
        width: 100%!important;
    }
    .hero-text {
        padding-right: 0;
    }
    .hero-section .container {
        flex-direction: column;
    }
    .action-btn {
        flex-direction: column;
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .action-btn a {
        width: 100%;
        max-width: 260px;
    }
    .hero-text p {
        font-size: 16px;
    }
    .hero-text h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .hero-section {
        padding: 50px 0;
    }
  }

