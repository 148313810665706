.brenda-detail ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px 0;
    li p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.5;
    }
}

.pagewithsidebar-inner {
    display: flex;
    width: 100%;
    padding-bottom: 80px;
    .tab-detail {
        width: calc(100% - 230px);
        padding: 0 0 0 50px;
    }
    
}


.tab-detail-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    .m-profile {
        display: flex;
        align-items: flex-start;
        font-size: 27px;
        text-transform: uppercase;
        color: #3075B6;
        font-weight: 700;
        img {
            margin-right: 10px;
        }
    }
    a {
        background: #1C4C7A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: capitalize;
        width: 200px;
        margin-left: 20px;
    }
}


.tab-detail-bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 50px;
}

h6 {
    font-size: 15px;
    font-weight: 400;
    color: #3075b6;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a.request-btn {
    background: #1C4C7A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

form.least-desired-condition span.checkbox-design {
    display: flex;
    margin-bottom: 10px;
    label {
        color: #000;
    }
}



form.least-desired-condition .one-fourth {
    width: 25%;
}

form.least-desired-condition.form-row {
    display: flex;
    width: 100%;
}

form.least-desired-condition .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

form.least-desired-condition span.checkbox-design label {
    margin: 0;
    line-height: 1;
}

form.least-desired-condition span.checkbox-design input[type="checkbox"] {
    min-width: 14px;
    height: 14px;
}

.checkbox-section {
    margin-top: 50px;
}

.least-desired-condition h6 {
    font-size: 12px;
    font-weight: 400;
    color: #3075b6;
    text-transform: capitalize;
    margin-bottom: 15px;
}